import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Card,
  Box,
  Button,
  TextField,
  InputBase,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import addImageIcon from "../../../assets/icons/add-image.svg";
import editIcon from "../../../assets/icons/edit-icon.svg";
import AircraftDetails from "./AircraftDetails";
import { makeStyles } from "@mui/styles";
import TechnicalSpecs from "./TechnicalSpecs";
import { toast } from "react-toastify";
import { serviceProvider } from "../../../provider/serviceProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../../configuration/config.json";
import { ValidationUtiltiy } from "../../../utility/ValidationUtility";
import { IAircraft } from "../../../interface/IAircraft";
import { ValidationType } from "../../../enum/validationType";
import { IValidationResponse } from "../../../interface/IValidation";
import backArrow from "../../../assets/icons/back-arrow.svg";
import SpinLoader from "../../loader/SpinLoader";
import CloseIcon from "@mui/icons-material/Close";
import { capitalizeString } from "../../../utility/capitalizeString";
import verifyIcon from "../../../assets/icons/successIcon.svg";
import EditorNew from "../../common/EditorNew";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: "inherit", // Text color
    width: "100%",
    backgroundColor: "transparent", // Remove background
    fontSize: "16px", // Set font size
    padding: "0", // Set padding
    "&:focus": {
      outline: "none", // Remove outline on focus
    },
  },
}));

const initialAircraftInput = {
  active: true,
  aircraftId: "",
  cabinSpecs: "",
  crewCapacity: 0,
  description: "",
  fleetId: "",
  headline: "",
  imageUrl: "",
  luggageCapacity: 0,
  name: "",
  operatorId: "",
  passengerCapacity: 0,
  pilotCapacity: 0,
  technicalSpecs: "",
};
const initialTechnicalSpecs = {
  exteriorLength: "",
  exteriorWingspan: "",
  exteriorHeight: "",
  range: "",
  highSpeed: "",
  typicalCruiseSpeed: "",
  engineModel: "",
  thrust: "",
  flatRelatedTo: "",
  takeOffDistance: "",
  landingDistance: "",
  avionics: "",
  maximumOperationAlt: "",
  initialCruiseAlt: "",
};
const initialCabinSpecs = {
  numberOfLavatory: 0,
  isWifiAvailable: "",
};
const EditAircraft = () => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();


  const [userInput, setUserInput] = useState<any>(null);
  const [cabinSpecs, setCabinSpecs] = useState<any>(null);
  const [technicalSpecs, setTechnicalSpecs] = useState<any>(null);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const { aircraftId } = useParams();
  
  const [images, setImages] = useState([] as any);
  const navigate = useNavigate();
  const [imageURLS, setImageURLs] = useState<any[]>(userInput?.imageUrl);
  const [aircraft,setAircraft]=useState<IAircraft | any>({});
  const [loader, setLoader] = useState(false);
  const [aircraftClasses,setAircraftClasses]=useState<any[]>([]);
  const [customUrl, setCustomUrl] = useState("");
  const [isCustomUrlVerified, setIsCustomUrlVerified] = useState<boolean>(false);
  const [data, setData] = useState("");
  const [error, setError] = useState("");

  // const [file,setFile]= useState<any>(null)
  const [files, setFiles] = useState<any>([]);

  const location = useLocation();

  // Accessing the search property which contains the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Getting the value of the 'index' parameter
  const tabIndex = queryParams.get('index');
  const fleetName = queryParams.get('fleetName');

  // Convert the 'index' value to a number if needed
  const tabIndexNumber = parseInt(tabIndex + "", 10);



  const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  // const [fileName,setFileName]=useState("")

  // const emptyInputFields=()=>{
  //     setCabinSpecs(initialCabinSpecs)
  //     setTechnicalSpecs(initialTechnicalSpecs);
  //     setUserInput(initialAircraftInput);
  //     setImageURLs([]);
  //     setImages([])
  // }

  const validateFieldsOnInput = (key: string) => {
    switch (key) {
      case "name":
        validateName();
        break;
      default:
        break;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const jsonData = {
      isWifiAvailable: cabinSpecs.isWifiAvailable,
      numberOfLavatory: cabinSpecs.numberOfLavatory,
    };
    const { name, value } = event.target;
    setUserInput({ ...userInput, [name]: value, cabinSpecs: jsonData });
    validateFieldsOnInput(name);
  };
  const handleChangeCabinSpecs = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setCabinSpecs({ ...cabinSpecs, [name]: value });
  };

  
  const handleTechnicalSpecsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setTechnicalSpecs({ ...technicalSpecs, [name]: value });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChooseFile = () => {
    // 👇️ open file input box on click of another element
    chooseFileRef.current?.click();
  };

  // const handleFileChange = (event: any) => {
  //   const fileObjs: [] = event.target.files;

  //   if (!fileObjs) {
  //     return;
  //   }

  //   const validFiles = Array.from(fileObjs).filter((fileObj) =>
  //     ValidationUtiltiy.isValidImg(fileObj["name"])
  //   );

  //   if (validFiles.length === 0) {
  //     toast.error("No valid files selected.");
  //     return;
  //   }
  //   console.log({ validFiles });
  //   setFiles(validFiles);

  //   validFiles.forEach((file: any, index: number) => {
  //     const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
  //     uploadImage(file, newFileName, index);
  //   });
  // };

  // const uploadImage = (file: any, newFile: any, index: number) => {
  //   setLoader(true)
  //   const ReactS3Client = new S3(awsConfig);
  //   ReactS3Client.uploadFile(file, awsConfig.aircraftsSubFolder + "/" + newFile)
  //     .then((data) => {
  //       console.log({ data }, index);
  //       if (data.status === 204) {
  //         // console.log({files})
  //         const img = data.location;
  //         // alert("uoload")
  //         setImageURLs((prevImageURLs) => [...prevImageURLs, data.location]);
  //         // console.log(data.location)

  //         // alert("heloo")

  //         // console.log(imageURLS,"updatedImageURLs",updatedImageURLs,index)
  //         if (index === files.length - 1) {
  //           updateUserInput(JSON.stringify(imageURLS));
  //           toast.success("Aircrafts Pictures Uploaded");
  //         }
  //       } else {
  //         // console.log("File Not Uploaded !!");
  //         toast.error("Error Occurred");
  //       }
  //       setLoader(false)
  //     })
  //     .catch((err) => {
  //       // console.log({err})
  //       setLoader(false)
  //       toast.error("Something Went Wrong.");
  //     });
  // };
  //   console.log(imageURLS)
  // console.log({ files, imageURLS });

  const handleFileChange = (event: any) => {
    const fileObjs: [] = event.target.files;

    if (!fileObjs) {
        return;
    }

    const validFiles = Array.from(fileObjs).filter(fileObj => ValidationUtiltiy.isValidImg(fileObj["name"]));

    if (validFiles.length === 0) {
        toast.error("No valid files selected.");
        return;
    }

    const uploadPromises = validFiles.map((file: any, index) => {
        const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
        return uploadImage(file, newFileName);
    });
    setLoader(true)
    Promise.all(uploadPromises)
        .then((uploadedURLs) => {
            setLoader(false)
            setImageURLs((prevImageURLs: any) => [...prevImageURLs, ...uploadedURLs]);
            updateUserInput(JSON.stringify(uploadedURLs));
            // toast.success("Image Uploaded");
            toast.success("Image Uploaded Click Save Button to Update.");
        })
        .catch((error) => {
            setLoader(false)
            console.error(error);
            toast.error("Error Occurred");
        });
};


// new added

const uploadImage = (file: any, newFile: any) => {
// setLoader(true)
    return new Promise((resolve, reject) => {
        const ReactS3Client = new S3(awsConfig);
        ReactS3Client.uploadFile(
            file,
            awsConfig.aircraftsSubFolder + "/" + newFile
        ).then((data) => {
            if (data.status === 204) {
                resolve(data.location);
            } else {
                reject("File Not Uploaded");
            }
            // setLoader(false)
        }).catch((err: any) => {
        //   setLoader(false)
            reject("Something Went Wrong.");
        });
    });
};

  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, imageUrl: imageUrl });
  };


  const customUrlVerify = async (customUrl: string) => {
    setLoader(true);
    try {
      const param = `customurls/valid/AIRCRAFT?customUrl=${customUrl}`;
      const res: any = await serviceProvider.commonService.verifyCustomUrl(param);
      if (res?.httpStatus === 200) {
        // setUrlVerified(true);
        setIsCustomUrlVerified(true)
        toast.success(res?.result || 'Custom url verified.');
      } else {
        toast.error(res?.error?.message || "Something went wrong!");
      }
    } catch (err: any) {
      toast.error(err?.data?.error?.message || "Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const handleEditAircraft = (aircraftId: string,fleetId:any,tabIndexNumber:any) => {
    if (!data.trim()) {
      setError("Description is required.");
      return;
    }
  
    setError(""); // Clear error if valid
    const payload = {
      ...userInput,
      technicalSpecs: JSON.stringify(technicalSpecs),
      cabinSpecs: JSON.stringify(cabinSpecs),
      imageUrl: JSON.stringify(imageURLS),
      custUrl: isCustomUrlVerified ? customUrl : "",
      description: data,
    };

    if(customUrl && !isCustomUrlVerified) {
      toast.error("Please verify custom url.");
      return
    } 

    isValidRequest()
      .then(() => {
        if (imageURLS.length == 0) {
          toast.error("Please Select Minimum 1 Images.");
          return;
        }else if(customUrl && !isCustomUrlVerified) {
          toast.error("Please verify custom url.");
          return
        } 
        setLoader(true);
        serviceProvider.aircraftService
          .editAircraft(aircraftId, payload)
          .then((res) => {
            setLoader(false);
            if (res.responseCode === 200) {
              toast.success(res.message);
              // fleets-management?fleetId=02505da8-f87f-4f40-b367-c0dc5af47b3a
              navigate(`/fleets-management?fleetId=${fleetId}&index=${tabIndexNumber}`);
              // window.len
              // setCabinSpecs()
            } else {
              toast.error(res?.message || "Something Went Wrong !!");
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message || "Something Went Wrong !!");
            setLoader(false);
            //   emptyInputFields()
            console.log({ err });
          });
      })
      .catch(() => {
        toast.error("Please fill all the Required Fields!");
      });
  };


  const handleRemoveImages =(index:number)=>{
    const newImageURLs = [...imageURLS];
    newImageURLs.splice(index, 1);
    setImageURLs(newImageURLs);
    // setImageURLs
  }


  const getAircraftDetails = () => {
    setLoader(true)
    serviceProvider.aircraftService.getAircraftDetails(aircraftId+"").then((res) => {
      
      // console.log("res",res)
      if(res.responseCode===200) {
        const parsedImages= JSON.parse(res.data[0].imageUrl);
        const parsedCabinSpecs =JSON.parse(res.data[0].cabinSpecs);
        const parsedtechnicalSpecs =JSON.parse(res.data[0].technicalSpecs);
        // const parsedImageUrl =JSON.parse(res.data[0].imageUrl);

        const newAircraftObj= {...res.data[0],imageUrl:parsedImages,cabinSpecs:parsedCabinSpecs,technicalSpecs:parsedtechnicalSpecs}
        setAircraft(newAircraftObj);
        setLoader(false)
      }else{
        setLoader(false)
      }
      
      // console.log({ res })
    }).catch((err) => {
      setLoader(false)
      console.log({ err })
    })

  }


  const getAircraftClasses = ()=>{
    setLoader(true);
    serviceProvider.aircraftSizeCategoryService.get("NA").then((res:any)=>{
        setLoader(false)
        if(res.responseCode ===200){
            setAircraftClasses(res.data)
        }else{
            toast.error(res.message || "Something Went Wrong!")
        }
    }).catch((err:any)=>{
        setLoader(false);
        console.log({err})
    })
}

useEffect(()=>{
  setEditorLoaded(true);
    getAircraftClasses()
},[]);



  useEffect(()=>{
    getAircraftDetails()
  },[]);

  useEffect(()=>{
    setData(userInput?.description)
    setUserInput(aircraft);
    setCustomUrl(aircraft?.custUrl);
    setIsCustomUrlVerified(true);
    setImageURLs(aircraft?.imageUrl)
    setCabinSpecs(aircraft?.cabinSpecs);
    setTechnicalSpecs(aircraft?.technicalSpecs)
  },[userInput?.description,aircraft])
  //   const getAircraftDetails = () => {
  //     setLoader(true)
  //     serviceProvider.aircraftService.getAircraftDetails(aircraftId).then((res) => {
  //       setLoader(false)
  //       // console.log("res",res)
  //       if(res.responseCode===200) {
  //         const parsedImages= JSON.parse(res.data[0].imageUrl);
  //         const parsedCabinSpecs =JSON.parse(res.data[0].cabinSpecs);
  //         const parsedtechnicalSpecs =JSON.parse(res.data[0].technicalSpecs);

  //         const newAircraftObj= {...res.data[0],imageUrl:parsedImages,cabinSpecs:parsedCabinSpecs,technicalSpecs:parsedtechnicalSpecs}
  //         setAircraft(newAircraftObj);
  //         // setUserInput(newAircraftObj)
  //       }
  //       // console.log({ res })
  //     }).catch((err) => {
  //       setLoader(false)
  //       console.log({ err })
  //     })

  //   }

  //   useEffect(() => {
  //     getAircraftDetails()
  //   }, [])

  //   useEffect(()=>{
  //     setUserInput({...aircraft})
  //     if(aircraft.cabinSpecs) setCabinSpecs(aircraft.cabinSpecs)
  //     if(aircraft.technicalSpecs)  setTechnicalSpecs(aircraft.technicalSpecs)

  //   },[aircraft])

  // useEffect(()=>{
  //     setUserInput(aircraft)
  // },[aircraft])

  //   console.log({userInput})
   useEffect(() => {
      setEditorLoaded(true);
    }, []);
  if (loader) {
    return <SpinLoader />;
  }

  return (
    <Grid container className="single-aircraft-container">
      <Grid item xs={12}>
        <Card>
          <Grid container>
            <Grid item xs={3} display="flex" alignItems="center">
              <Box className="back-btn-container">
                <Tooltip title="back">
                  <IconButton onClick={() => navigate(-1)}>
                    <img src={backArrow} alt="back" />
                  </IconButton>
                </Tooltip>
                <Typography variant="h6" component="h6" pl="20px">
                  Name of the aircraft
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <InputBase
                id="aircraft-name"
                className={classes.input}
                //   placeholder="Brand Name"
                // startAdornment="Name of aircraft"
                name="name"
                value={userInput?.name}
                // value={user}
                onChange={handleInputChange}
                inputProps={{ "aria-label": "custom input" }}
              />
            </Grid>
          </Grid>
        </Card>
        <span className="modalError" id="errorDiv-aircraft-name"></span>
      </Grid>

      <Grid item xs={12} mt="20px">
        <Grid container spacing={3} className="aircraft-image-container">
          {userInput &&
            imageURLS?.length >= 1 &&
            imageURLS?.map((imageSrc: string,index:number) => {
              return (
                <Grid item xs={3}>
                  <Card className="image-card">
                   <div className="img-box">
                   <img src={imageSrc} alt="img" width={"100%"} />
                    <div className="delete-icon" onClick={()=>handleRemoveImages(index)}>
                    <Tooltip title="Remove">
                    <CloseIcon />
                    </Tooltip>
                    </div>
                   </div>
                    
                  </Card>
                </Grid>
              );
            })}
          <Grid item xs={3}>
            <Card className="image-card">
              <input
                type="file"
                multiple
                accept="image/*"
                ref={chooseFileRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <img
                src={addImageIcon}
                alt="img"
                onClick={handleChooseFile}
                className="add-img-icon"
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
              <Card className="aircraft-description-card" sx={{mb:"0 !important"}}>
                <Typography
                  variant="body1"
                  component="p"
                  className="description-heading"
                >
                  Summary
                </Typography>
                
                <InputBase
                  
                  className={classes.input}
                  multiline
                  //   minRows={6}
                  minRows={4}
                  //   maxRows={10}
                  //   placeholder="Brand Name"
                  name="summary"
                  value={userInput?.summary || ""}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "custom input" }}
                />
              </Card>
            </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Description
          </Typography>
          <EditorNew
  placeholder="Write the description"
  onChange={(data: string) => {
    setData(data); // Set editor data
  }}
  editorLoaded={editorLoaded} // Assuming editorLoaded is defined somewhere
  value={userInput?.description}
/>
          {/* <InputBase
            id="aircraft-description"
            className={classes.input}
            multiline
            //   minRows={6}
            minRows={4}
            //   maxRows={10}
            //   placeholder="Brand Name"
            name="description"
            value={userInput?.description}
            onChange={handleInputChange}
            inputProps={{ "aria-label": "custom input" }}
          />
          {/* <div style={{ marginTop: "20px" }}>
                      <EditorNew
                        // id="content"
                        editorLoaded={editorLoaded}
                        value={userInput?.description}
                        onChange={(data: string) => {
                          if (data) {
                            errors["content"] = "";
                          }
                          setUserInput({ ...userInput, description: data });
                        }}
                        placeholder="Description"
                      />
                      {errors?.content && (
                        <span className="modalError">{errors?.content}</span>
                      )}
                    </div> */}
        </Card>
        {error && (<span className="modalError" >{error}</span>)}
      </Grid>
      {
                fleetName === "Business Jet" && 
                
                <Grid item xs={12}>
                <Card className="aircraft-description-card" sx={{mt:"0 !important"}}>
                <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
              Aircraft size category
            </Typography>
                  <FormControl fullWidth>
                    <Select
                      className={classes.input}
                      sx={{ padding: "0 10px" }}
                      // value={"No"}
                      // defaultValue={cabinSpecs?.isWifiAvailable}
                      value={userInput?.aircraftSizeCategoryId}
                      name="aircraftSizeCategoryId"
                      variant="standard"
                      disableUnderline
                      onChange={handleInputChange}
                      // {...(errors.aircraftSizeCategoryId && {
                      //   error: true,
                      //   helperText: errors.aircraftSizeCategoryId,
                      // })}
                      // onChange={handleChangeCabinSpecs}
                      // onChange={handleSelectChange}
                    >
                      {
  aircraftClasses
    ?.filter((item: any) => {
      const categoryName = item?.aircraftSizeCategoryName?.toLowerCase();
      return categoryName !== 'turboprop' && categoryName !== 'helicopter';
    })
    .map((item: any) => {
      return <MenuItem value={item?.aircraftSizeCategoryId}>{capitalizeString(item?.aircraftSizeCategoryName)}</MenuItem>
    })
}

                      
                     
                    </Select>
                  </FormControl>
                  </Card>
                </Grid> 
              } 

<Grid item xs={12}>
                <Card className="aircraft-description-card" sx={{mt:"0 !important"}} >
                <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
             Custom Url
            </Typography>
                  <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    // disableUnderline
                      className={classes.input}
                      id="custUrl"
                      value={customUrl}
                      onChange={(e) => {
                        setIsCustomUrlVerified(false);
                        setCustomUrl(e.target.value);
                      }}
                      // required
                      placeholder="Enter custom url"
                     
                      // sx={{ mt: 1 }}
                      InputProps={{
                      disableUnderline: true,
                        endAdornment: (
                          <Button
                            className="common-button-underlined"
                            onClick={() => customUrlVerify(customUrl)} // Replace handleVerify with your verify logic function
                            sx={{ padding:"0" }}
                            disabled={isCustomUrlVerified}
                          >
                          <p style={{fontSize:"14px",display:"flex",alignItems:"center",gap:"4px"}}>  
                            <span>{isCustomUrlVerified ? "Verified" : "Verify"}</span>
                            {isCustomUrlVerified ? (
                              <img src={verifyIcon} alt="verify-icon" />
                            ) : (
                              ""
                            )}</p>
                          </Button>
                        ),
                      }}
                    />
                  </FormControl>
                  </Card>
                </Grid>
      <Grid item xs={12} className="tabs-container">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Aircraft details" {...a11yProps(0)} />
              <Tab label="Technical Specs" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <AircraftDetails
              userInput={userInput}
              handleInputChange={handleInputChange}
              cabinSpecs={cabinSpecs}
              handleChangeCabinSpecs={handleChangeCabinSpecs}
              isEditable={true}
              aircraft={aircraft}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TechnicalSpecs
              technicalSpecs={technicalSpecs}
              handleTechnicalSpecsChange={handleTechnicalSpecsChange}
              isEditable={true}
              aircraft={aircraft}
            />
            {/* <TechnicalSpecs /> */}
          </CustomTabPanel>
        </Box>
      </Grid>
      <Grid item xs={12} className="btn-container">
      {/* fleets-management?fleetId=02505da8-f87f-4f40-b367-c0dc5af47b3a */}
        <Button
          variant="contained"
          className="rounded-btn-fill"
          onClick={() => handleEditAircraft(aircraftId + "",aircraft?.fleetId,tabIndexNumber)}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditAircraft;

const isValidRequest = (): Promise<boolean> => {
  let responseArray: IValidationResponse[] = [];

  responseArray.push(validateName());
  // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
  let haveErrors =
    responseArray.filter((x) => {
      return !x.isValid;
    }).length <= 0;
  return new Promise((resolve, reject) => {
    if (haveErrors) {
      resolve(true);
    } else {
      reject(false);
    }
  });
};

const validateName = () => {
  return ValidationUtiltiy.validateElement(
    "aircraft-name",
    ValidationType.Text,
    true,
    "errorDiv-aircraft-name",
    "Name"
  );
};

