import React,{useState,useEffect} from 'react';
import { getFormattedDate } from '../../utility/formateDateAndTime';
import { IFlight } from '../../interface/IFlight';
import { serviceProvider } from '../../provider/serviceProvider';
import  { Dayjs } from 'dayjs';
import DataGridTable from '../DataGridTable/DataGridTable';
import CommonModal from '../modal/CommonModal';
import { Tooltip } from '@mui/material';
import { formatNumber } from '../../utility/formateNumber';
// import EditEmptyLeg from './EditEmptyLeg';



const BMJRewardPointTable = ({ pageState, setPageState }: any) => {
  const [loader, setLoader] = useState(false);
  // const classes= useStyles()

  const [open,setOpen]=useState(false);

  const handleOpenModal =()=> setOpen(true);
  const handleCloseModal = ()=>setOpen(false);


  // Full name, Email, Mobile Number, Points redeemed, Points gained

  const rows = pageState?.data?.map((rewardPoint: any, index: number) => ({
    ...rewardPoint,
    // id: rewardPoint.rewardPointId,
    id: rewardPoint.uId + index,
    rewardPointsRedeemed: formatNumber(rewardPoint?.rewardPointsRedeemed),
    rewardPointsEarned: formatNumber(rewardPoint?.rewardPointsEarned)

    // recordCreatedTs: getFormattedDate(rewardPoint.recordCreatedTs),
    // endTime: getFormattedTime(airport.operatingEndTime),
  }));


  const columns = [
    {
      field: 'entityId',
      headerName: 'Entity Id',
      width: 250,
      editable: false
    },
    {
      field: 'userFullName',
      headerName: 'Full Name',
      width: 180,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row?.userFullName}>
            <span>{params.row?.userFullName}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      width: 300,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row?.userEmail}>
            <span>{params.row?.userEmail}</span>
          </Tooltip>
        );
      },
      
    },
    {
      field: 'userMobileNumber',
      headerName: 'Mobile Number',
      width: 200,
      editable: false,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row.category}>
            <span>{params.row?.category}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'rewardPointsRedeemed',
      headerName: 'Points redeemed',
      // type: 'number',
      width: 180,
      // width: 300,
      editable: false,
    },
    {      // field: 'Points gained',
      // headerName: 'rewardPointsEarned',

      field: 'rewardPointsEarned',
      headerName: 'Points gained',
      width: 180,
      // width: 150,
      editable: false,
    },

   
  ];


  
 


  console.log({rows,columns})


  return (
    <>
    {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody} /> */}
    <DataGridTable
      pageState={pageState}
      setPageState={setPageState}
      rows={rows}
      columns={columns}
    />
   </>
  );
}
export default BMJRewardPointTable;
