import React, { useState, useEffect } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Card,
  Box,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import addImageIcon from "../../../assets/icons/add-image.svg";
import editIcon from "../../../assets/icons/edit-icon.svg";
import AircraftDetails from "./AircraftDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { serviceProvider } from "../../../provider/serviceProvider";
import TechnicalSpecs from "./TechnicalSpecs";
import { IAircraft } from "../../../interface/IAircraft";
import backArrow from "../../../assets/icons/back-arrow.svg";
import SpinLoader from "../../loader/SpinLoader";
import { toast } from "react-toastify";
import { capitalizeString } from "../../../utility/capitalizeString";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SingleAircraft = () => {
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [aircraft, setAircraft] = useState<IAircraft | any>({});
  const [aircraftClasses, setAircraftClasses] = useState<any[]>([]);

  const { aircraftId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Accessing the search property which contains the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Getting the value of the 'index' parameter
  const index = queryParams.get("index");

  // Convert the 'index' value to a number if needed
  // const indexNumber = parseInt(index, 10);

  // const aircraftImages= JSON.parse(aircraft.imageUrl)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  console.log({ aircraft });

  const getAircraftDetails = () => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAircraftDetails(aircraftId + "")
      .then((res) => {
        setLoader(false);
        // console.log("res",res)
        if (res.responseCode === 200) {
          const parsedImages = JSON.parse(res.data[0].imageUrl);
          const parsedCabinSpecs = JSON.parse(res.data[0].cabinSpecs);
          const parsedtechnicalSpecs = JSON.parse(res.data[0].technicalSpecs);

          const newAircraftObj = {
            ...res.data[0],
            imageUrl: parsedImages,
            cabinSpecs: parsedCabinSpecs,
            technicalSpecs: parsedtechnicalSpecs,
          };
          setAircraft(newAircraftObj);
        }
        // console.log({ res })
      })
      .catch((err) => {
        setLoader(false);
        console.log({ err });
      });
  };

  const getAircraftClasses = () => {
    setLoader(true);
    serviceProvider.aircraftSizeCategoryService
      .get("NA")
      .then((res: any) => {
        setLoader(false);
        if (res.responseCode === 200) {
          setAircraftClasses(res.data);
        } else {
          toast.error(res.message || "Something Went Wrong!");
        }
      })
      .catch((err: any) => {
        setLoader(false);
        console.log({ err });
      });
  };

  useEffect(() => {
    getAircraftClasses();
  }, []);

  // const saveAircraftToURL = (aircraft:IAircraft) => {
  //   const encodedAircraftObj = encodeURIComponent(JSON.stringify(aircraft));
  //   const newURL = `/fleets/aircraft/edit/${aircraft.aircraftId}?aircraft=${encodedAircraftObj}`;
  //   navigate(newURL);
  // };

  useEffect(() => {
    getAircraftDetails();
  }, []);



  
  if (loader) {
    return <SpinLoader />;
  }
  return (
    <Grid container className="single-aircraft-container">
      <Grid item xs={12}>
        <Box className="back-btn-container">
          <Tooltip title="back">
            <IconButton onClick={() => navigate(-1)}>
              <img src={backArrow} alt="back" />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" component="h6">
            {aircraft.name}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className="edit-heading-container">
          <Typography variant="h6" component="h6" textAlign="right">
            Edit Details
          </Typography>
          <Button
            onClick={() =>
              navigate(`/fleets/aircraft/edit/${aircraftId}?index=${index}&fleetName=${aircraft?.fleetDetails?.name}`)
            }
            // onClick={()=>saveAircraftToURL(aircraft)}
          >
            <img src={editIcon} alt="edit icon" width="20px" />
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} className="aircraft-image-container">
          {aircraft &&
            aircraft?.imageUrl?.map((image: any) => {
              return (
                <Grid item xs={3}>
                  <Card className="image-card">
                    <div className="img-box">
                      <img src={image} alt="img" width={"100%"} />
                      {/* <div className="delete-icon" onClick={()=>handleRemoveImages(index)}>
                    <Tooltip title="Remove">
                    <CloseIcon />
                    </Tooltip>
                    </div> */}
                    </div>
                    {/* <img src={image} alt="img" /> */}
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Summary
          </Typography>
          <Typography 
  variant="body1" 
  component="p" 
  >{aircraft?.summary}</Typography>


        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card">
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Description
          </Typography>
          <Typography 
  variant="body1" 
  component="p" 
  dangerouslySetInnerHTML={{ __html: aircraft.description }} 
/>

        </Card>
      </Grid>
      {
      aircraft?.fleetDetails?.fleetId === aircraft?.fleetId && (
        <Grid item xs={12}>
          <Card className="aircraft-description-card" sx={{mt:"0 !important"}}>
            <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
              Aircraft size category
            </Typography>
            <Typography variant="body1" component="p">
              {
                capitalizeString(
                aircraftClasses
                  ?.filter(
                    (item: any) =>
                      item?.aircraftSizeCategoryId ===
                      aircraft?.aircraftSizeCategoryId
                  )
                  ?.map((item: any) => item?.aircraftSizeCategoryName)[0] || "") // Extract the name of the first (and only) matched item
              }
            </Typography>
          </Card>
        </Grid>
      )}

      <Grid item xs={12} className="tabs-container">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Aircraft details" {...a11yProps(0)} />
              <Tab label="Technical Specs" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <AircraftDetails isEditable={false} aircraft={aircraft} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TechnicalSpecs isEditable={false} aircraft={aircraft} />
          </CustomTabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SingleAircraft;
