import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  MenuItem,
  FormControl,
  Typography,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import styles from "./Job.module.scss";
import veriryIcon from "../../assets/icons/successIcon.svg";
import EditorNew from "../../components/common/EditorNew";
import ButtonComponent from "../../components/common/ButtonComponent";
import { toast } from "react-toastify";
import { serviceProvider } from "../../provider/serviceProvider";
const EditBlogModalBody = ({
  isActive,
  handleCloseModal: onEditModalClose,
  blogDetails,
  pageState,
  getBlogsList,
}: any) => {
  const [loaders, setLoaders] = useState<any>({
    editBlog: false,
    verfiyCustomUrl: false,
    category: false,
  });
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [userInput, setUserInput] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [customUrl, setCustomUrl] = useState("");
  const [isCustomUrlVerified, setIsCustomUrlVerified] =
    useState<boolean>(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
    if (value) {
      errors[name] = "";
    }
  };
  const handleMinExperienceChange = (field: string, value: string) => {
    let numValue = parseInt(value, 10) || 0;

    setUserInput((prev) => {
      let years = parseInt(prev.experienceMinYears || "0", 10);
      let months = parseInt(prev.experienceMinMonths || "0", 10);

      if (field === "experienceMinMonths") {
        months = numValue;
        if (months >= 12) {
          years += Math.floor(months / 12);
          months = months % 12;
        }
      } else if (field === "experienceMinYears") {
        years = numValue;
      }

      return {
        ...prev,
        experienceMin: `${years}.${months}`, // Final formatted value
      };
    });
  };
  const handleMaxExperienceChange = (field: string, value: string) => {
    let numValue = parseInt(value, 10) || 0;

    setUserInput((prev) => {
      let years = parseInt(prev.experienceMaxYears || "0", 10);
      let months = parseInt(prev.experienceMaxMonths || "0", 10);

      if (field === "experienceMaxMonths") {
        months = numValue;
        if (months >= 12) {
          years += Math.floor(months / 12);
          months = months % 12;
        }
      } else if (field === "experienceMaxYears") {
        years = numValue;
      }

      return {
        ...prev,
        experienceMax: `${years}.${months}`, // Final formatted value
      };
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput((prev) => ({
      ...prev,
      showSalary: event.target.checked, // Toggle showSalary value
    }));
  };
  const customUrlVerify = async (customUrl: string) => {
    setLoaders((prev: any) => ({ ...prev, verfiyCustomUrl: true }));
    try {
      const param = `customurls/valid/JOB?customUrl=${customUrl}`;
      const res: any = await serviceProvider.blogService.checkCustomUrl(param);
      if (res?.httpStatus === 200) {
        // setUrlVerified(true);
        setIsCustomUrlVerified(true);
        toast.success(res?.result || "Custom url verified.");
      } else {
        toast.error(res?.error?.message || "Something went wrong!");
      }
    } catch (err: any) {
      toast.error(err?.data?.error?.message || "Something went wrong!");
    } finally {
      setLoaders((prev: any) => ({ ...prev, verfiyCustomUrl: false }));
    }
  };

  const handleUpdateBlog = async () => {
    if (
      !userInput.jobTitle ||
      !userInput.jobType ||
      !userInput.location[0] ||
      !userInput.experienceMin ||
      !userInput.experienceMax ||
      !userInput.description
    ) {
      setErrors({
        ...errors,
        // Set error messages for each field that is empty
        jobTitle: !userInput.jobTitle ? "Job title is required" : "",
        jobType: !userInput.jobType ? "Job type is required" : "",
        location: !userInput.location[0] ? "Job Location is required" : "",
        experienceMin: !userInput.experienceMin
          ? "Min experience is required"
          : "",
        experienceMax: !userInput.experienceMax
          ? "Max experience is required"
          : "",
        description: !userInput.description
          ? "Job description is required"
          : "",
      });
    } else {
      setLoaders((prev: any) => ({ ...prev, editBlog: true }));
      const payload = {
        ...userInput,
        custUrl: isCustomUrlVerified ? customUrl : "",
      };

      if (customUrl && !isCustomUrlVerified) {
        toast.error("Please verify custom url.");
        return;
      }
      try {
        const res: any = await serviceProvider.jobService.put(
          blogDetails?.jobId,
          payload
        );

        if (res?.responseCode === 200) {
          toast.success(res?.message || "Job updated successfully");
          onEditModalClose();
          const query = `search?uId=NA&categoryId=NA&name=${"NA"}&tags=NA&content=NA&categoryName=NA&blogpostId=NA&custUrl=NA&offset=${
            pageState.page
          }&limit=${
            pageState.pageSize
          }&order=recordUpdatedTs.desc&profile=BASIC&title=NA&isActive=${isActive}&isFeatured=false`;
          getBlogsList(query);
        } else {
          toast.error(res?.message || "something went wrong");
        }
      } catch (err: any) {
        toast.error(err?.data?.error?.message || "error");
      } finally {
        setLoaders((prev: any) => ({ ...prev, editBlog: false }));
      }
    }
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    setIsCustomUrlVerified(true);
    setCustomUrl(blogDetails?.custUrl);
    if (blogDetails) {
      setUserInput(blogDetails);
    }
  }, [blogDetails]);

  useEffect(() => {
    if (userInput?.experienceMin) {
      const experienceStr = String(userInput.experienceMin); // Ensure it's a string
      const [years, months] = experienceStr.split(".").map(Number);

      setUserInput((prev) => ({
        ...prev,
        experienceMinYears: (years || 0).toString(),
        experienceMinMonths: (months || 0).toString(),
      }));
    }
  }, [userInput?.experienceMin]);
  useEffect(() => {
    if (userInput?.experienceMax) {
      const experienceStr = String(userInput.experienceMax); // Ensure it's a string
      const [years, months] = experienceStr.split(".").map(Number);

      setUserInput((prev) => ({
        ...prev,
        experienceMaxYears: (years || 0).toString(),
        experienceMaxMonths: (months || 0).toString(),
      }));
    }
  }, [userInput?.experienceMax]);

  const jobTypes = [
    { value: "FULL_TIME", label: "Full Time" },
    { value: "PART_TIME", label: "Part Time" },
    { value: "CONTRACT_BASED", label: "Contract Based" },
    { value: "INTERNSHIP", label: "Internship" },
    { value: "FREELANCE", label: "Freelance" },
  ];

  return (
    <>
      <Grid container rowGap="20px">
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Edit Job
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowGap="20px" spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography>Job Title*</Typography>
              <Grid container className={styles.depatureArrivalAirportBox}>
                <Grid item xs={12} className={styles.border}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    name="jobTitle"
                    value={userInput?.jobTitle}
                    onChange={handleInputChange}
                    variant="standard"
                    required
                    placeholder="Job Title"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>
              {errors?.jobTitle && (
                <span className="modalError">{errors?.jobTitle}</span>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>Job Type*</Typography>
              <Grid container className={styles.JobType}>
                <Grid item xs={12} className={styles.border}>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    id="jobType"
                    name="jobType"
                    value={userInput?.jobType || ""}
                    onChange={handleInputChange}
                    // displayEmpty
                    inputProps={{
                      name: "jobType",
                      id: "jobType",
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Removes the border
                        padding: 0,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Ensures no border on hover
                        padding: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Ensures no border when focused
                        padding: 0,
                      },
                    }}
                  >
                    {jobTypes.map((job) => (
                      <MenuItem key={job.value} value={job.value}>
                        {job.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              {errors?.jobType && (
                <span className="modalError">{errors?.jobType}</span>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Job Location*</Typography>
              <Grid container className={styles.depatureArrivalAirportBox}>
                <Grid item xs={12} className={styles.border}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    name="location"
                    value={userInput?.location[0]}
                    onChange={(e) => {
                      const updatedLocation = [e.target.value]; // Wrap the value in an array
                      setUserInput((prevState) => ({
                        ...prevState,
                        location: updatedLocation,
                      })); // Update the formData state
                    }}
                    variant="standard"
                    required
                    placeholder="Job Title"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>
              {errors?.location && (
                <span className="modalError">{errors?.location}</span>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Custom URL</Typography>
              <Grid container className={styles.depatureArrivalAirportBox}>
                <Grid item xs={12} className={styles.border}>
                  {/* <WUETypography variant={"h6"}>Custom URL</WUETypography> */}

                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    id="custUrl"
                    variant="standard"
                    value={customUrl}
                    onChange={(e) => {
                      setIsCustomUrlVerified(false);
                      setCustomUrl(e.target.value);
                    }}
                    // required
                    placeholder="Enter custom url"
                    // sx={{ mt: 1 }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <Button
                          className="common-button-underlined"
                          onClick={() => customUrlVerify(customUrl)} // Replace handleVerify with your verify logic function
                          sx={{ padding: "0" }}
                          disabled={
                            isCustomUrlVerified || loaders.verfiyCustomUrl
                          }
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              paddingLeft: "20px",
                            }}
                          >
                            <span>
                              {isCustomUrlVerified ? "Verified" : "Verify"}
                            </span>
                            {isCustomUrlVerified ? (
                              <img src={veriryIcon} alt="verify-icon" />
                            ) : (
                              ""
                            )}
                          </p>
                        </Button>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Min Experience*</Typography>
              <Grid
                container
                className={styles.depatureArrivalAirportBox}
                alignItems="center"
              >
                {/* Years Dropdown */}
                <Grid item xs={6} className={styles.border}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="standard"
                    value={userInput?.experienceMinYears || "0"}
                    onChange={(e) =>
                      handleMinExperienceChange(
                        "experienceMinYears",
                        e.target.value
                      )
                    }
                    InputProps={{ disableUnderline: true }}
                  >
                    {[...Array(41)].map((_, i) => (
                      <MenuItem key={i} value={i.toString()}>
                        {i} Years
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Months Dropdown */}
                <Grid item xs={6} className={styles.border}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="standard"
                    value={userInput?.experienceMinMonths || "0"}
                    onChange={(e) =>
                      handleMinExperienceChange(
                        "experienceMinMonths",
                        e.target.value
                      )
                    }
                    InputProps={{ disableUnderline: true }}
                  >
                    {[...Array(11)].map((_, i) => (
                      <MenuItem key={i + 1} value={(i + 1).toString()}>
                        {i + 1} Months
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              {errors?.experienceMin && (
                <span className="modalError">{errors?.experienceMin}</span>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Max Experience*</Typography>
              <Grid
                container
                className={styles.depatureArrivalAirportBox}
                alignItems="center"
              >
                {/* Years Dropdown */}
                <Grid item xs={6} className={styles.border}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="standard"
                    value={userInput?.experienceMaxYears || "0"}
                    onChange={(e) =>
                      handleMaxExperienceChange(
                        "experienceMaxYears",
                        e.target.value
                      )
                    }
                    InputProps={{ disableUnderline: true }}
                  >
                    {[...Array(41)].map((_, i) => (
                      <MenuItem key={i} value={i.toString()}>
                        {i} Years
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Months Dropdown */}
                <Grid item xs={6} className={styles.border}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="standard"
                    value={userInput?.experienceMaxMonths || "0"}
                    onChange={(e) =>
                      handleMaxExperienceChange(
                        "experienceMaxMonths",
                        e.target.value
                      )
                    }
                    InputProps={{ disableUnderline: true }}
                  >
                    {[...Array(11)].map((_, i) => (
                      <MenuItem key={i + 1} value={(i + 1).toString()}>
                        {i + 1} Months
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              {errors?.experienceMax && (
                <span className="modalError">{errors?.experienceMax}</span>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Salary Expectation*</Typography>
              <Grid container className={styles.depatureArrivalAirportBox}>
                <Grid item xs={12} className={styles.border}>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    id="salaryExpectation"
                    name="salaryExpectation"
                    variant="standard"
                    required
                    value={userInput?.salaryExpectation}
                    onChange={handleInputChange}
                    placeholder="Salary (in LPA)"
                    InputProps={{
                      disableUnderline: true,
                      // sx:{m:"10px"}
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Show Salary Checkbox */}
            <Grid item xs={12} md={6} display='flex' alignItems='flex-end'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(userInput?.showSalary)}
                    onChange={handleCheckboxChange} // Use checkbox-specific handler
                    name="showSalary"
                  />
                }
                label="Show Salary to Candidates"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth required>
                <InputLabel
                  sx={{ fontWeight: 600, color: "#4A4A4A" }}
                  shrink
                  required
                >
                  Job Description
                </InputLabel>
                <div style={{ marginTop: "20px" }}>
                  <EditorNew
                    // id="content"

                    editorLoaded={editorLoaded}
                    value={userInput?.description}
                    onChange={(data: string) => {
                      if (data) {
                        errors["description"] = "";
                      }
                      setUserInput({ ...userInput, description: data });
                    }}
                    placeholder="Write Description"
                  />
                  {errors?.description && (
                    <span className="modalError">{errors?.description}</span>
                  )}
                </div>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonComponent
                loader={loaders.editBlog}
                disabled={loaders.editBlog}
                fullWidth={false}
                onClick={handleUpdateBlog}
                label="Update"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditBlogModalBody;
