import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Card,
  Box,
  Button,
  TextField,
  InputBase,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import addImageIcon from "../../../assets/icons/add-image.svg";
import editIcon from "../../../assets/icons/edit-icon.svg";
import AircraftDetails from "./AircraftDetails";
import { makeStyles } from "@mui/styles";
import TechnicalSpecs from "./TechnicalSpecs";
import { toast } from "react-toastify";
import { serviceProvider } from "../../../provider/serviceProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../../configuration/config.json";
import { ValidationUtiltiy } from "../../../utility/ValidationUtility";
import { IValidationResponse } from "../../../interface/IValidation";
import { ValidationType } from "../../../enum/validationType";
import backArrow from "../../../assets/icons/back-arrow.svg";
import SpinLoader from "../../loader/SpinLoader";
import { capitalizeString } from "../../../utility/capitalizeString";
import veriryIcon from "../../../assets/icons/successIcon.svg";
import EditorNew from "../../common/EditorNew";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: "inherit", // Text color
    width: "100%",
    backgroundColor: "transparent", // Remove background
    fontSize: "16px", // Set font size
    padding: "0", // Set padding
    "&:focus": {
      outline: "none", // Remove outline on focus
    },
  },
}));

const initialAircraftInput = {
  active: true,
  aircraftId: "",
  aircraftPerHourCost: 0,
  aircraftMinimumRunwayLength: 0,
  aircraftSpeed: 0,
  aircraftSizeCategoryId: "",
  cabinSpecs: "",
  crewCapacity: 0,
  fleetId: "",
  headline: "",
  imageUrl: "",
  luggageCapacity: 0,
  name: "",
  operatorId: "",
  passengerCapacity: 0,
  seatingCapacity:"",
  summary:"",
  pilotCapacity: 0,
  technicalSpecs: "",
};
const initialTechnicalSpecs = {
  exteriorLength: "",
  exteriorWingspan: "",
  exteriorHeight: "",
  range: "",
  highSpeed: "",
  speed:"",
  typicalCruiseSpeed: "",
  engineModel: "",
  thrust: "",
  flatRelatedTo: "",
  takeOffDistance: "",
  landingDistance: "",
  avionics: "",
  maximumOperationAlt: "",
  initialCruiseAlt: "",
};
const initialCabinSpecs = {
  numberOfLavatory: 0,
  isWifiAvailable: "No",
};
const AddAircraft = () => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [userInput, setUserInput] = useState(initialAircraftInput);
  const [cabinSpecs, setCabinSpecs] = useState(initialCabinSpecs);
  const [technicalSpecs, setTechnicalSpecs] = useState(initialTechnicalSpecs);
  const [aircraftClasses,setAircraftClasses]=useState<any[]>([]);
  // console.log("technical specs",technicalSpecs)
  const { fleetId } = useParams();

  const location = useLocation();

  // Accessing the search property which contains the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Getting the value of the 'index' parameter
  const index = queryParams.get('index');
  const fleetName = queryParams.get('fleetName');

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);
  // console.log({fleetId})
  const [images, setImages] = useState([] as any);
  const [imageURLS, setImageURLs] = useState<any[]>([]);

  const [loader, setLoader] = useState(false);
  const [files, setFiles] = useState([]); // Change from "const [file, setFile] = useState<any>(null);"
  const [customUrl, setCustomUrl] = useState("");
  const [isCustomUrlVerified, setIsCustomUrlVerified] = useState<boolean>(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  const [error, setError] = useState("");


  const chooseFileRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);
  // const [fileName,setFileName]=useState("")
  const navigate = useNavigate();


 
  
  const emptyInputFields = () => {
    setCabinSpecs(initialCabinSpecs);
    setTechnicalSpecs(initialTechnicalSpecs);
    setUserInput(initialAircraftInput);
    setImageURLs([]);
    setImages([]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const jsonData = {
      isWifiAvailable: cabinSpecs.isWifiAvailable,
      numberOfLavatory: cabinSpecs.numberOfLavatory,
    };
    const { name, value } = event.target;
    setUserInput({
      ...userInput,
      [name]: value,
      cabinSpecs: JSON.stringify(jsonData),
      imageUrl: JSON.stringify(imageURLS),
    });

    validateFieldsOnInput(name);
  };

  const validateFieldsOnInput = (key: string) => {
    switch (key) {
      case "name":
        validateName();
        break;
      default:
        break;
    }
  };

  const handleChangeCabinSpecs = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setCabinSpecs({ ...cabinSpecs, [name]: value });
  };
  const handleTechnicalSpecsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setTechnicalSpecs({ ...technicalSpecs, [name]: value });
  };

  console.log({ files });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChooseFile = () => {
    // 👇️ open file input box on click of another element
    chooseFileRef.current?.click();
  };

  // const handleFileChange = (event: any) => {
  //   const fileObjs: [] = event.target.files;

  //   if (!fileObjs) {
  //     return;
  //   }

  //   const validFiles = Array.from(fileObjs).filter((fileObj) =>
  //     ValidationUtiltiy.isValidImg(fileObj["name"])
  //   );

  //   if (validFiles.length === 0) {
  //     toast.error("No valid files selected.");
  //     return;
  //   }
  //   console.log({ validFiles });
  //   setFiles(validFiles);

  //   validFiles.forEach((file: any, index) => {
  //     const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
  //     uploadImage(file, newFileName, index);
  //   });
  // };

  // const uploadImage = (file: any, newFile: any, index: any) => {
  //   const ReactS3Client = new S3(awsConfig);
  //   ReactS3Client.uploadFile(file, awsConfig.aircraftsSubFolder + "/" + newFile)
  //     .then((data) => {
  //       if (data.status === 204) {
  //         console.log(files);
  //         const img = data.location;
  //         setImageURLs((prevImageURLs) => [...prevImageURLs, data.location]);
  //         console.log(data.location);

  //         // alert("heloo")

  //         // console.log(imageURLS,"updatedImageURLs",updatedImageURLs,index)
  //         if (index === files.length - 1) {
  //           updateUserInput(JSON.stringify(imageURLS));
  //           // toast.success("Aircrafts Pictures Uploaded");
  //           toast.success("Image Uploaded Click Save Button to Update.");
  //         }
  //       } else {
  //         console.log("File Not Uploaded !!");
  //         toast.error("Error Occurred");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //       toast.error("Something Went Wrong.");
  //     });
  // };
  // console.log(imageURLS);

  const handleFileChange = (event: any) => {
    const fileObjs: [] = event.target.files;

    if (!fileObjs) {
        return;
    }

    const validFiles = Array.from(fileObjs).filter(fileObj => ValidationUtiltiy.isValidImg(fileObj["name"]));

    if (validFiles.length === 0) {
        toast.error("No valid files selected.");
        return;
    }

    const uploadPromises = validFiles.map((file: any, index) => {
        const newFileName = "123" + "_" + file.name.replace(/\..+$/, "");
        return uploadImage(file, newFileName);
    });
    setLoader(true)
    Promise.all(uploadPromises)
        .then((uploadedURLs) => {
            setLoader(false)
            setImageURLs((prevImageURLs: any) => [...prevImageURLs, ...uploadedURLs]);
            updateUserInput(JSON.stringify(uploadedURLs));
            // toast.success("Image Uploaded");
            toast.success("Image Uploaded Click Save Button to Update.");
        })
        .catch((error) => {
            setLoader(false)
            console.error(error);
            toast.error("Error Occurred");
        });
};


// new added

const uploadImage = (file: any, newFile: any) => {
// setLoader(true)
    return new Promise((resolve, reject) => {
        const ReactS3Client = new S3(awsConfig);
        ReactS3Client.uploadFile(
            file,
            awsConfig.aircraftsSubFolder + "/" + newFile
        ).then((data) => {
            if (data.status === 204) {
                resolve(data.location);
            } else {
                reject("File Not Uploaded");
            }
            // setLoader(false)
        }).catch((err: any) => {
        //   setLoader(false)
            reject("Something Went Wrong.");
        });
    });
};




  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, imageUrl: imageUrl });
  };

  const customUrlVerify = async (customUrl: string) => {
    setLoader(true);
    try {
      const param = `customurls/valid/AIRCRAFT?customUrl=${customUrl}`;
      const res: any = await serviceProvider.commonService.verifyCustomUrl(param);
      if (res?.httpStatus === 200) {
        // setUrlVerified(true);
        setIsCustomUrlVerified(true)
        toast.success(res?.result || 'Custom url verified.');
      } else {
        toast.error(res?.error?.message || "Something went wrong!");
      }
    } catch (err: any) {
      toast.error(err?.data?.error?.message || "Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //     if (images.length < 1) return;
  //     const newImageUrls: any = [];
  //     images.forEach((image: any) => newImageUrls.push(URL.createObjectURL(image)));
  //     setImageURLs(newImageUrls);
  // }, [images]);

  const handleAddAircraft = (fleetId:any,indexNumber:any) => {
    if (!data.trim()) {
      setError("Description is required.");
      return;
    }
  
    setError(""); // Clear error if valid
  
    const payload = {
      ...userInput,
      fleetId: fleetId + "",
      technicalSpecs: JSON.stringify(technicalSpecs),
      custUrl: isCustomUrlVerified ? customUrl : "",
      description: data,
    };
    isValidRequest()
      .then(() => {
        if (imageURLS.length <= 3) {
          // alert(files.length)
          // console.log({files});

          toast.error("Please Select Minimum 4 Images.");
          return;
        }else if(customUrl && !isCustomUrlVerified) {
          toast.error("Please verify custom url.");
          return
        } 
        setLoader(true);
        serviceProvider.aircraftService
          .post(payload)
          .then((res) => {
            setLoader(false);
            if (res.responseCode === 200) {
              toast.success(res.message);
              navigate(`/fleets-management?fleetId=${fleetId}&index=${indexNumber}`)
              emptyInputFields();
              // setCabinSpecs()
            }else{
              toast.error(res?.message||"Something Went Wrong !!")
            }
          })
          .catch((err) => {
            toast.error(err?.data?.message || "Something Went Wrong !!");
            setLoader(false);
            //   emptyInputFields()
            console.log({ err });
          });
      })
      .catch(() => {
        toast.error("Please fill all the Required Fields!");
      });
  };







  const getAircraftClasses = ()=>{
      setLoader(true);
      serviceProvider.aircraftSizeCategoryService.get("NA").then((res:any)=>{
          setLoader(false)
          if(res.responseCode ===200){
              setAircraftClasses(res.data)
          }else{
              toast.error(res.message || "Something Went Wrong!")
          }
      }).catch((err:any)=>{
          setLoader(false);
          console.log({err})
      })
  }

  useEffect(()=>{
      getAircraftClasses()
  },[]);
  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  if(loader){
  return  <SpinLoader/>
  }

  return (
    <Grid container className="single-aircraft-container">
      <Grid item xs={12}>
        <Grid container mb="10px">
          <Grid item xs={6}>
            <Box className="back-btn-container">
              <Tooltip title="back">
                <IconButton onClick={() => navigate(-1)}>
                  <img src={backArrow} alt="back" />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" component="h6">
                Add Aircraft
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6}>
    <Box className="edit-heading-container">

 
<Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography>
 <Button>
     <img src={editIcon} alt="edit icon" width="20px" />
 </Button>

</Box>
    </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Grid container>
            <Grid item xs={3} display="flex" alignItems="center">
              <Typography variant="h6" component="h6" pl="20px">
                Name of the aircraft
              </Typography>
              {/* <Typography variant="h6" component="h6" pl="20px">Name of the aircraft</Typography> */}
            </Grid>
            <Grid item xs={9}>
              <InputBase
                id="aircraft-name"
                className={classes.input}
                //   placeholder="Brand Name"
                // startAdornment="Name of aircraft"
                name="name"
                value={userInput.name}
                onChange={handleInputChange}
                inputProps={{ "aria-label": "custom input" }}
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Card>
        <span className="modalError" id="errorDiv-aircraft-name"></span>
      </Grid>
      {/* <Grid item xs={12}>
                <Box className="edit-heading-container">
                    <Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography>
                    <Button onClick={()=>navigate(`/fleets/aircraft/add/${fleetId}`)}>
                        <img src={editIcon} alt="edit icon" width="20px" />
                    </Button>
                </Box>

            </Grid> */}
      <Grid item xs={12} mt="20px">
        <Grid container spacing={3} className="aircraft-image-container">
          {/* <Grid item xs={3}>
                        <Card className='image-card'>
                            <img src={addImageIcon} alt="img" />
                        </Card>
                        
                    </Grid> */}

          {/* <Upload /> */}

          {imageURLS.map((imageSrc) => {
            return (
              <Grid item xs={3}>
                <Card className="image-card">
                  <img src={imageSrc} alt="img" width={"100%"} />
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={3}>
            <Card className="image-card">
              <input
                type="file"
                multiple
                accept="image/*"
                ref={chooseFileRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <img
                src={addImageIcon}
                alt="img"
                onClick={handleChooseFile}
                className="add-img-icon"
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card" sx={{mb:"0 !important"}}>
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Summary
          </Typography>
          
          <InputBase
            id="aircraft-summary"
            className={classes.input}
            multiline
            //   minRows={6}
            minRows={4}
            //   maxRows={10}
            //   placeholder="Brand Name"
            name="summary"
            value={userInput?.summary}
            onChange={handleInputChange}
            inputProps={{ "aria-label": "custom input" }}
          />
        </Card>
        {/* {error && (<span className="modalError" >{error}</span>)} */}
      </Grid>
      <Grid item xs={12}>
        <Card className="aircraft-description-card" sx={{mb:"0 !important"}}>
          <Typography
            variant="body1"
            component="p"
            className="description-heading"
          >
            Description
          </Typography>
          <EditorNew
  placeholder="Write the description"
  onChange={(data: string) => {
    setData(data); // Set editor data
  }}
  editorLoaded={editorLoaded} // Assuming editorLoaded is defined somewhere
  value={data}
/>
          {/* <InputBase
            id="aircraft-description"
            className={classes.input}
            multiline
            //   minRows={6}
            minRows={4}
            //   maxRows={10}
            //   placeholder="Brand Name"
            name="description"
            value={userInput.description}
            onChange={handleInputChange}
            inputProps={{ "aria-label": "custom input" }}
          /> */}
        </Card>
        {error && (<span className="modalError" >{error}</span>)}
      </Grid>
   
      

              {
                fleetName === "Business Jet" && <Grid item xs={12}>
                <Card className="aircraft-description-card" >
                <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
              Aircraft size category
            </Typography>
                  <FormControl fullWidth>
                    <Select
                      className={classes.input}
                      sx={{ padding: "0 10px" }}
                      // value={"No"}
                      // defaultValue={cabinSpecs?.isWifiAvailable}
                      value={userInput?.aircraftSizeCategoryId}
                      name="aircraftSizeCategoryId"
                      variant="standard"
                      disableUnderline
                      onChange={handleInputChange}
                      // {...(errors.aircraftSizeCategoryId && {
                      //   error: true,
                      //   helperText: errors.aircraftSizeCategoryId,
                      // })}
                      // onChange={handleChangeCabinSpecs}
                      // onChange={handleSelectChange}
                    >
                      {
  aircraftClasses
    ?.filter((item: any) => {
      const categoryName = item?.aircraftSizeCategoryName?.toLowerCase();
      return categoryName !== 'turboprop' && categoryName !== 'helicopter';
    })
    .map((item: any) => {
      return <MenuItem value={item?.aircraftSizeCategoryId}>{capitalizeString(item?.aircraftSizeCategoryName)}</MenuItem>
    })
}

                      
                     
                    </Select>
                  </FormControl>
                  </Card>
                </Grid> 
              } 

<Grid item xs={12}>
                <Card className="aircraft-description-card" >
                <Typography
              variant="body1"
              component="p"
              className="description-heading"
            >
             Custom Url
            </Typography>
                  <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    // disableUnderline
                      className={classes.input}
                      id="custUrl"
                      value={customUrl}
                      onChange={(e) => {
                        setIsCustomUrlVerified(false)
                        setCustomUrl(e.target.value);
                      }}
                      // required
                      placeholder="Enter custom url"
                     
                      // sx={{ mt: 1 }}
                      InputProps={{
                      disableUnderline: true,
                        endAdornment: (
                          <Button
                          className="common-button-underlined"
                          onClick={() => customUrlVerify(customUrl)} // Replace handleVerify with your verify logic function
                          sx={{ padding:"0" }}
                          disabled={isCustomUrlVerified}
                        >
                        <p style={{fontSize:"14px",display:"flex",alignItems:"center",gap:"4px"}}>  
                          <span>{isCustomUrlVerified ? "Verified" : "Verify"}</span>
                          {isCustomUrlVerified ? (
                            <img src={veriryIcon} alt="verify-icon" />
                          ) : (
                            ""
                          )}</p>
                        </Button>
                        ),
                      }}
                    />
                  </FormControl>
                  </Card>
                </Grid>
                 
              
              
           
      <Grid item xs={12} className="tabs-container">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Aircraft details" {...a11yProps(0)} />
              <Tab label="Technical Specs" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <AircraftDetails
              userInput={userInput}
              handleInputChange={handleInputChange}
              cabinSpecs={cabinSpecs}
              handleChangeCabinSpecs={handleChangeCabinSpecs}
              isEditable={true}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TechnicalSpecs
              technicalSpecs={technicalSpecs}
              handleTechnicalSpecsChange={handleTechnicalSpecsChange}
              isEditable={true}
            />
            {/* <TechnicalSpecs /> */}
          </CustomTabPanel>
        </Box>
      </Grid>
      <Grid item xs={12} className="btn-container">
        <Button
          variant="contained"
          className="rounded-btn-fill"
          onClick={()=>handleAddAircraft(fleetId,indexNumber)}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddAircraft;

const isValidRequest = (): Promise<boolean> => {
  let responseArray: IValidationResponse[] = [];

  responseArray.push(validateName());
  // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
  let haveErrors =
    responseArray.filter((x) => {
      return !x.isValid;
    }).length <= 0;
  return new Promise((resolve, reject) => {
    if (haveErrors) {
      resolve(true);
    } else {
      reject(false);
    }
  });
};

const validateName = () => {
  return ValidationUtiltiy.validateElement(
    "aircraft-name",
    ValidationType.Text,
    true,
    "errorDiv-aircraft-name",
    "Name"
  );
};

