import React, { useState, useEffect, useRef } from "react";
import AutoCompleteComponent from "../../common/AutoCompleteComponent";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { IAirport } from "../../../interface/IAirport";
import styles from "./GenerateQuotation.module.scss";
import seatIcon from "../../../assets/icons/seat.svg";
import departureIcon from "../../../assets/icons/depature-icon.svg";
import destinationIcon from "../../../assets/icons/destination-icon.svg";
import DateTimePickerComponent from "../../common/DateTimePickerComponent";
import dayjs from "dayjs";
import ButtonComponent from "../../common/ButtonComponent";

interface Route {
  from: string;
  to: string;
  fromAirportName: string;
  toAirportName: string;
  fhandler: string;
  thandler: string;
  pcount: string;
  date: string;
}

interface AssignedRoute {
  route: Route;
  flightDuration: string;
  hours?: number;
  minutes?: number;
}

interface Aircraft {
  aircraftName: string;
  aircraftId: string;
  assignedRoutes: AssignedRoute[];
  currency: string;
  amount: string;
  engineType: string;
  seatingRange: string;
}

const EditAircraftAndAmountCurrencyWithRoute = ({
  aircrafts,
  fleetAircrafts,
  airports,
  onUpdateQuotation,
  onClose,
}: {
  aircrafts: Aircraft[];
  fleetAircrafts: any[];
  airports: any[];
  onUpdateQuotation: (updatedAircrafts: Aircraft[]) => void;
  onClose: () => void;
}) => {
  const [editableAircrafts, setEditableAircrafts] = useState<Aircraft[]>([
    ...aircrafts,
  ]);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateRoutes = (
    aircraftIndex: number,
    routeIndex: number,
    from: string,
    to: string
  ) => {
    const newErrors = { ...errors }; // Copy existing errors

    if (from === to) {
      newErrors[`${aircraftIndex}-${routeIndex}-from-to`] =
        "From and To airports cannot be the same.";
    } else {
      delete newErrors[`${aircraftIndex}-${routeIndex}-from-to`];
    }

    setErrors(newErrors); // Update the errors state
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};

    editableAircrafts.forEach((aircraft, aircraftIndex) => {
      if (!aircraft.amount) {
        newErrors[`${aircraftIndex}-amount`] = "Amount is required.";
      }
      if (!aircraft.seatingRange) {
        newErrors[`${aircraftIndex}-seatingCapacity`] = "Seating capacity is required.";
      }
      aircraft.assignedRoutes.forEach((route, routeIndex) => {
        const { route: routeDetails, flightDuration } = route;
        if (!routeDetails.pcount) {
          newErrors[`${aircraftIndex}-${routeIndex}-pcount`] =
            "Passenger count is required.";
        }
        if (routeDetails.from === routeDetails.to) {
          newErrors[`${aircraftIndex}-${routeIndex}-from-to`] =
            "From and To airports cannot be the same.";
        }
        const durationParts = flightDuration.split(" ");
        const hasHours = durationParts.some(part => part === "hour" || part === "hours");
        const hasMinutes = durationParts.some(part => part === "minute" || part === "minutes");
        if (!hasHours && !hasMinutes) {
          newErrors[`${aircraftIndex}-${routeIndex}-flightDuration`] =
            "Flight duration must have hours or minutes.";
        }
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (
    aircraftIndex: number,
    routeIndex: number,
    field: keyof Route | keyof AssignedRoute,
    value: string | number
  ) => {
    const updatedAircrafts = [...editableAircrafts];
    const aircraft = updatedAircrafts[aircraftIndex];
    const assignedRoute = aircraft.assignedRoutes[routeIndex];

    if (field in assignedRoute.route) {
      (assignedRoute.route as any)[field] = value;
    } else if (field in assignedRoute) {
      (assignedRoute as any)[field] = value;
    }
    setEditableAircrafts(updatedAircrafts);
    clearError(`${aircraftIndex}-${routeIndex}-${field}`);
  };

  const handleAircraftChange = (
    aircraftIndex: number,
    updates: { [key: string]: any }
  ) => {
    const updatedAircrafts = [...editableAircrafts]; // Shallow copy of the array
    updatedAircrafts[aircraftIndex] = {
      ...updatedAircrafts[aircraftIndex], // Shallow copy of the aircraft object
      ...updates, // Merge the updates object
    };

    setEditableAircrafts(updatedAircrafts); // Update the state with the new array
    clearError(`${aircraftIndex}-amount`);
  };

  const handleSave = () => {
    if (validateFields()) {
      onUpdateQuotation(editableAircrafts);
    }
  };
  const clearError = (key: string) => {
    setErrors((prev) => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <Typography variant="h5">Edit Aircraft and Route</Typography>
      </Grid>
      {editableAircrafts.map((aircraft, aircraftIndex) => (
        <Grid key={aircraft.aircraftId}>
          <Grid item xs={12} mb={1}>
            <Typography variant="h6">Aircraft Details</Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography>
                Aircraft Name:<b>{aircraft.aircraftName}</b>
              </Typography>
              <Grid container className={styles.depatureArrivalAirportBox}>
                <Grid item xs={12} className={styles.border}>
                  <Autocomplete
                    options={fleetAircrafts}
                    getOptionLabel={(option) => option.name}
                    value={
                      fleetAircrafts.find(
                        (item) => item.aircraftId === aircraft.aircraftId
                      ) || null
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    onChange={(event, selectedOption) => {
                      if (selectedOption) {
                        handleAircraftChange(aircraftIndex, {
                          aircraftName: selectedOption.name,
                          aircraftId: selectedOption.aircraftId,
                        });
                      } else {
                        handleAircraftChange(aircraftIndex, {
                          aircraftName: "",
                          aircraftId: null,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        type="text"
                        variant="standard"
                        fullWidth
                      />
                    )}
                    disableClearable
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography>Amount:</Typography>
              <Grid container className={styles.quotationInput}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="standard"
                  value={aircraft.amount}
                  placeholder="Enter amount"
                  onChange={(e) =>
                    handleAircraftChange(aircraftIndex, {
                      amount: e.target.value,
                    })
                  }
                  InputProps={{
                    disableUnderline: true,
                  }}
                  error={!!errors[`${aircraftIndex}-amount`]}
                  helperText={errors[`${aircraftIndex}-amount`] || ""}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography>Currency:</Typography>
              <Grid container className={styles.quotationEditCurrency}>
                <Select
                  label="Currency"
                  value={aircraft.currency || ""}
                  fullWidth
                  displayEmpty
                  onChange={(e) =>
                    handleAircraftChange(aircraftIndex, {
                      currency: e.target.value,
                    })
                  }
                  sx={{
                    border: "none", // Removes the border
                    boxShadow: "none", // Removes any shadow
                    padding: " 0", // Adjust padding if needed
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none", // Ensures no outline in certain variants
                    },
                  }}
                >
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} >
              <Typography>Engine type:</Typography>
              <Grid container className={styles.quotationEditCurrency}>
                <Select
                  label="Engine Type"
                  value={aircraft.engineType || ""}
                  fullWidth
                  displayEmpty
                  onChange={(e) =>
                    handleAircraftChange(aircraftIndex, {
                      engineType: e.target.value,
                    })
                  }
                  sx={{
                    border: "none", // Removes the border
                    boxShadow: "none", // Removes any shadow
                    padding: " 0", // Adjust padding if needed
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none", // Ensures no outline in certain variants
                    },
                  }}
                >
                  <MenuItem value="Single engine Helicopter">Single Engine Helicopter</MenuItem>
                  <MenuItem value="Single Engine Turboprop">Single Engine Turboprop</MenuItem>
                  <MenuItem value="Twin Engine Helicopter">Twin Engine Helicopter</MenuItem>
                  <MenuItem value="Twin Engine Turboprop">Twin Engine Turboprop</MenuItem>
                  <MenuItem value="Twin Engine Business Jet">Twin Engine Business Jet</MenuItem>
                  <MenuItem value="Three Engine Business jet">Three Engine Business jet</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} >
              <Typography sx={{mb:1}}>Seating Capacity:</Typography>
              <Grid container className={styles.quotationInput}>
              <TextField
                  fullWidth
                  size="small"
                  type="text"
                  variant="standard"
                  value={aircraft.seatingRange}
                  placeholder="Enter seating capacity"
                  onChange={(e) =>
                    handleAircraftChange(aircraftIndex, {
                      seatingRange: e.target.value,
                    })
                  }
                  InputProps={{
                    disableUnderline: true,
                  }}
                  error={!!errors[`${aircraftIndex}-seatingCapacity`]}
                  helperText={errors[`${aircraftIndex}-seatingCapacity`] || ""}
                />
              </Grid>
            </Grid>
          </Grid>

          {aircraft.assignedRoutes.map((assignedRoute, routeIndex) => (
            <Grid
              container
              mb={4}
              className={styles.ItenaryConatiner}
              key={routeIndex}
            >
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={3}>
                  <Typography>
                    From Airport:<b>{assignedRoute.route.fromAirportName}</b>
                  </Typography>
                  <Grid container className={styles.depatureArrivalAirportBox}>
                    <Grid item xs={12} className={styles.border}>
                      <AutoCompleteComponent
                        options={airports} // List of airport options
                        getOptionLabel={(option: IAirport) =>
                          `${option.name} (${option.icaoCode}) - ${option.city}`
                        } // Custom label for each option
                        renderOption={(props: any, option: IAirport) => (
                          <Box
                            sx={{
                              width: "100%",
                              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                            {...props}
                          >
                            <Typography textAlign="left">
                              {option.name} ({option.icaoCode}) - {option.city}
                            </Typography>
                          </Box>
                        )}
                        value={assignedRoute.route.fromAirportName || ""}
                        fullWidth
                        onChange={(event, selectedOption: IAirport) => {
                          handleInputChange(
                            aircraftIndex,
                            routeIndex,
                            "fromAirportName",
                            selectedOption ? selectedOption.name : ""
                          );
                          handleInputChange(
                            aircraftIndex,
                            routeIndex,
                            "from",
                            selectedOption ? selectedOption.airportId : ""
                          );
                          validateRoutes(
                            aircraftIndex,
                            routeIndex,
                            selectedOption.airportId,
                            assignedRoute.route.to
                          );
                        }}
                      >
                        <img
                          src={departureIcon}
                          alt="departure-icon"
                          height="25px"
                          width="25px"
                        />
                      </AutoCompleteComponent>
                    </Grid>
                  </Grid>
                  {errors[`${aircraftIndex}-${routeIndex}-from-to`] && (
                    <span className="modalError">
                      {errors[`${aircraftIndex}-${routeIndex}-from-to`]}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography>From Handler:</Typography>
                  <Grid container className={styles.quotationInput}>
                    <TextField
                      size="small"
                      type="text"
                      variant="standard"
                      placeholder="Enter from handler"
                      value={assignedRoute.route.fhandler}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                      }}
                      onChange={(e) =>
                        handleInputChange(
                          aircraftIndex,
                          routeIndex,
                          "fhandler",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>
                    To Airport:<b>{assignedRoute.route.toAirportName}</b>
                  </Typography>
                  <Grid container className={styles.depatureArrivalAirportBox}>
                    <Grid item xs={12} className={styles.border}>
                      <AutoCompleteComponent
                        options={airports}
                        getOptionLabel={(option: IAirport) =>
                          `${option.name} (${option.icaoCode}) - ${option.city}`
                        }
                        renderOption={(props: any, option: IAirport) => (
                          <Box
                            sx={{
                              width: "100%",
                              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                            {...props}
                          >
                            <Typography textAlign="left">
                              {option.name} ({option.icaoCode}) - {option.city}
                            </Typography>
                          </Box>
                        )}
                        value={assignedRoute.route.toAirportName}
                        fullWidth
                        onChange={(event, selectedOption: IAirport) => {
                          handleInputChange(
                            aircraftIndex,
                            routeIndex,
                            "toAirportName",
                            selectedOption ? selectedOption.name : ""
                          );
                          handleInputChange(
                            aircraftIndex,
                            routeIndex,
                            "to",
                            selectedOption ? selectedOption.airportId : ""
                          );
                          validateRoutes(
                            aircraftIndex,
                            routeIndex,
                            assignedRoute.route.from,
                            selectedOption.airportId
                          );
                        }}
                      >
                        <img
                          src={destinationIcon}
                          alt="departure-icon"
                          height="25px"
                          width="25px"
                        />
                      </AutoCompleteComponent>
                    </Grid>
                  </Grid>
                  {errors[`${aircraftIndex}-${routeIndex}-from-to`] && (
                    <span className="modalError">
                      {errors[`${aircraftIndex}-${routeIndex}-from-to`]}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography>To Handler:</Typography>
                  <Grid container className={styles.quotationInput}>
                    <TextField
                      size="small"
                      type="text"
                      variant="standard"
                      placeholder="Enter to handler"
                      value={assignedRoute.route.thandler}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                      }}
                      onChange={(e) =>
                        handleInputChange(
                          aircraftIndex,
                          routeIndex,
                          "thandler",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography>Passenger Count:</Typography>
                  <Grid container className={styles.searchInput}>
                    <TextField
                      className={styles.customInput}
                      placeholder="Number of Passengers"
                      value={assignedRoute.route.pcount}
                      fullWidth
                      variant="standard"
                      size="small"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={seatIcon}
                              alt="seat icon"
                              height="25px"
                              width="25px"
                            />
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      onChange={(e) =>
                        handleInputChange(
                          aircraftIndex,
                          routeIndex,
                          "pcount",
                          e.target.value
                        )
                      }
                      error={!!errors[`${aircraftIndex}-${routeIndex}-pcount`]}
                      helperText={
                        errors[`${aircraftIndex}-${routeIndex}-pcount`]
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>Start Date:</Typography>
                  <Grid container className={styles.depatureArrivalAirportBox}>
                    <DateTimePickerComponent
                      disablePast={true}
                      placeholder="Start Date and Time"
                      value={
                        assignedRoute.route.date
                          ? dayjs(assignedRoute.route.date)
                          : null
                      }
                      fullWidth
                      onChange={(value) =>
                        handleInputChange(
                          aircraftIndex,
                          routeIndex,
                          "date",
                          value ? value.toISOString() : ""
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>Flight Duration:</Typography>
                  <Grid container spacing={2}>
  {/* Hours Input */}
  <Grid item xs={6}>
    <Grid container className={styles.quotationInput}>
      <TextField
        label="Hours"
        value={
          assignedRoute.flightDuration &&
          assignedRoute.flightDuration.includes("hour")
            ? assignedRoute.flightDuration.split(" ")[0] // Extract hours
            : ""
        }
        onChange={(e) => {
          const hours = e.target.value.trim(); // New hours value
          const currentDuration = assignedRoute.flightDuration || ""; // Existing flightDuration
          if (hours === "0") return;
          
          // Extract minutes if available
          const minutes = currentDuration.includes("minute")
            ? currentDuration
                .split(" ")
                .find((part, index, array) => array[index + 1]?.includes("minute")) || ""
            : "";

          if (!hours && !minutes) {
            setErrors((prev) => ({
              ...prev,
              [`${aircraftIndex}-${routeIndex}-flightDuration`]:
                "At least one field (Hours or Minutes) is required.",
            }));
            return;
          }

          setErrors((prev) => {
            const updatedErrors = { ...prev };
            delete updatedErrors[`${aircraftIndex}-${routeIndex}-flightDuration`];
            return updatedErrors;
          });

          // 🛠️ Singular/Plural Handling
          const hoursText = hours ? `${hours} hour${hours === "1" ? "" : "s"}` : "";
          const minutesText = minutes ? `${minutes} minute${minutes === "1" ? "" : "s"}` : "";

          const updatedDuration = `${hoursText}${hours && minutes ? " " : ""}${minutesText}`;
          
          handleInputChange(aircraftIndex, routeIndex, "flightDuration", updatedDuration.trim());
        }}
        variant="standard"
        size="small"
        fullWidth
        type="number"
        InputProps={{
          disableUnderline: true,
        }}
        error={!!errors[`${aircraftIndex}-${routeIndex}-flightDuration`]}
        helperText={errors[`${aircraftIndex}-${routeIndex}-flightDuration`]}
      />
    </Grid>
  </Grid>

  {/* Minutes Input */}
  <Grid item xs={6}>
    <Grid container className={styles.quotationInput}>
      <TextField
        label="Minutes"
        value={
          assignedRoute.flightDuration &&
          assignedRoute.flightDuration.includes("minute")
            ? assignedRoute.flightDuration
                .split(" ")
                .find((part, index, array) => array[index + 1]?.includes("minute")) || ""
            : ""
        }
        onChange={(e) => {
          const minutes = e.target.value.trim(); // New minutes value
          const currentDuration = assignedRoute.flightDuration || ""; // Existing flightDuration
          if (minutes === "0" || parseInt(minutes) > 59) return;

          // Extract hours if available
          const hours = currentDuration.includes("hour")
            ? currentDuration
                .split(" ")
                .find((part, index, array) => array[index + 1]?.includes("hour")) || ""
            : "";

          if (!hours && !minutes) {
            setErrors((prev) => ({
              ...prev,
              [`${aircraftIndex}-${routeIndex}-flightDuration`]:
                "At least one field (Hours or Minutes) is required.",
            }));
            return;
          }

          setErrors((prev) => {
            const updatedErrors = { ...prev };
            delete updatedErrors[`${aircraftIndex}-${routeIndex}-flightDuration`];
            return updatedErrors;
          });

          // 🛠️ Singular/Plural Handling
          const hoursText = hours ? `${hours} hour${hours === "1" ? "" : "s"}` : "";
          const minutesText = minutes ? `${minutes} minute${minutes === "1" ? "" : "s"}` : "";

          const updatedDuration = `${hoursText}${hours && minutes ? " " : ""}${minutesText}`;
          
          handleInputChange(aircraftIndex, routeIndex, "flightDuration", updatedDuration.trim());
        }}
        variant="standard"
        size="small"
        fullWidth
        type="number"
        InputProps={{
          disableUnderline: true,
        }}
        error={!!errors[`${aircraftIndex}-${routeIndex}-flightDuration`]}
        helperText={errors[`${aircraftIndex}-${routeIndex}-flightDuration`]}
      />
    </Grid>
  </Grid>
</Grid>

                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}
      <Grid container item spacing={2} justifyContent="flex-end">
        <Grid item>
          <ButtonComponent
            fullWidth={false}
            onClick={handleSave}
            label="Update"
          />
        </Grid>
        <Grid item>
          <ButtonComponent fullWidth={false} onClick={onClose} label="Cancel" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditAircraftAndAmountCurrencyWithRoute;
