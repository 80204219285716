import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, CircularProgress, Grid, Button, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { serviceProvider } from "../../provider/serviceProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetAppIcon from "@mui/icons-material/GetApp"; // Material-UI download icon
import { JobType } from "../../enum/jobType";

const JobDetails = () => {
  const { id } = useParams<{ id: string }>(); // Get jobId from the route
  const location = useLocation(); // Access the full location object to extract query parameters
  const [custUrl, setCustUrl] = useState<string | null>(null);

  const [jobDetails, setJobDetails] = useState<any | null>(null);
  const [applications, setApplications] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 5,
    loader: true,
    data: [],
  });
    const navigate = useNavigate();

  // Fetch job details
  const getJobDetails = (param: string) => {
    setLoading(true);
    serviceProvider.jobService
      .get(param)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setJobDetails(res?.data?.data[0]); // Assuming data is an array
        }
      })
      .catch((err) => {
        console.error("Error fetching job details:", err);
      })
      .finally(() => setLoading(false));
  };

  // Fetch applications
  const getApplications = (param: string) => {
    setPageState((prev) => ({ ...prev, loader: true }));
    serviceProvider.jobService
      .get(param)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setApplications(res?.data?.data || []);
        }
      })
      .catch((err) => {
        console.error("Error fetching applications:", err);
      })
      .finally(() => {
        setPageState((prev) => ({ ...prev, loader: false }));
      });
  };

  // Extract `custUrl` from query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const custUrlParam = queryParams.get("custUrl");
    setCustUrl(custUrlParam);
  }, [location.search]);

  // Trigger data fetching when `id` or `custUrl` changes
  useEffect(() => {
    if (id) {
      const queryJobDetails = `search?jobId=${id}`;
      getJobDetails(queryJobDetails);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const queryApplications = `apply/search?jobId=${id}&isActive=true&&offset=${pageState.page}&limit=${pageState.pageSize}`;
      getApplications(queryApplications);
    }
  }, [id, pageState.page, pageState.pageSize]);

  // DataGrid columns
  const columns: GridColDef[] = [
    { field: "fullName", headerName: "Full Name", width: 250 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "mobileNumber", headerName: "Mobile Number", width: 180 },
    { field: "currentOrganization", headerName: "Current Organization", width: 180 },
    { field: "currentCTC", headerName: "Current CTC", width: 180 },
    {
      field: "resumeUrl",
      headerName: "Download Resume",
      width: 150,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent='center' spacing={1}>
      {/* Download Icon */}
      <Grid item>
        <IconButton
          color="primary"
          href={params.value}
          target="_blank"
        >
          <Typography fontWeight={700}>View</Typography>
        </IconButton>
      </Grid>
      {/* Generate PDF Label */}
      
    </Grid>
        
      ),
    }, 
      ];

  // DataGrid rows
  const rows = applications.map((app, index) => ({
    id: index, // Add a unique key for each row
    fullName: app.fullName,
    email: app.email,
    mobileNumber: app.mobileNumber,
    currentOrganization: app.currentOrganization,
    currentCTC: app.currentCTC,
    resumeUrl: app.resumeUrl,
    
  }));

  return (
    <Box sx={{ padding: 3 }}>
      <Grid item>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/jobs-management")}
          sx={{
            textTransform: "none",
            color: "primary.main",
            fontWeight: "500",
          }}
        >
          Back
        </Button>
      </Grid>
      {/* Job Details Section */}
      {loading ? (
  <CircularProgress />
) : jobDetails ? (
  <Box
    sx={{
      marginBottom: 3,
      padding: "10px 20px ",
      boxShadow: "2px 2px rgb(191, 191, 191)",
      borderRadius: 2,
      backgroundColor: "#fff",
    }}
  >
    <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 2 }}>
      {jobDetails.jobTitle}
    </Typography>
    <Grid container spacing={2}>
      {/* Annual CTC */}
      <Grid item xs={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
          Salary
        </Typography>
        <Typography variant="body2">
          {jobDetails.salaryExpectation}
        </Typography>
      </Grid>

      {/* Experience */}
      <Grid item xs={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
          Experience
        </Typography>
        <Typography variant="body2">
          {jobDetails.experienceMin} - {jobDetails.experienceMax} years
        </Typography>
      </Grid>

      {/* Job Type */}
      <Grid item xs={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
          Job Type
        </Typography>
        <Typography variant="body2">
        {JobType[jobDetails.jobType as keyof typeof JobType]}
        </Typography>
      </Grid>

      {/* Location */}
      <Grid item xs={3}>
        <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
          Location
        </Typography>
        <Typography variant="body2">
          {jobDetails.location?.length
            ? jobDetails.location.join(", ")
            : "Not Specified"}
        </Typography>
      </Grid>

      {/* Total Applications */}
      <Grid item xs={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
          Total Applications
        </Typography>
        <Typography variant="body2">
          {jobDetails.applicantCount || "No Applicants Yet"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
        Job Description
        </Typography>
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: jobDetails.description }} />
      </Grid>
    </Grid>
  </Box>
) : (
  <Typography variant="body1">No job details available.</Typography>
)}

      {/* Applications DataGrid */}
      <Box>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Applications
        </Typography>
        {pageState.loader ? (
          <CircularProgress />
        ) : (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageState.pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection
              onPageChange={(page) =>
                setPageState((prev) => ({ ...prev, page }))
              }
              onPageSizeChange={(pageSize) =>
                setPageState((prev) => ({ ...prev, pageSize }))
              }
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default JobDetails;
